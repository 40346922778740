import '@/assets/styles/vars/css-variables.scss'
import '@/assets/styles/fonts.scss'
import '@/assets/styles/global.scss'
import '@/assets/styles/swiper.scss'

import type { AppProps, AppContext } from 'next/app'
import { Head, NProgress, Layout, Notifications } from '@/components'
import { HostProvider } from '@/contexts/HostContext'
import { NavProvider } from '@/contexts/NavContext'
import { NotificationProvider } from '@/contexts/NotificationContext'
import { getHost } from '@/helpers/getHost'
import { Provider } from 'react-redux'
import { useStore } from '@/store'
import Router from 'next/router'
import Error from './_error'
import App from 'next/app'
import YMTraker from '@/metrics/YM'
import GTraker from '@/metrics/Gtag'

Router.events.on('routeChangeComplete', url => {
  YMTraker.pageview(url)
  GTraker.pageview(url)
})

interface MyAppProps extends AppProps {
  host: string
  hasError: boolean
  errorStatus: number
}

export default function MyApp({ Component, pageProps, host, hasError, errorStatus }: MyAppProps) {
  const store = useStore(pageProps?.initialReduxState)

  return hasError ? (
    <>
      <Head />
      <NavProvider>
        <Layout>
          <NProgress />
          <Error {...pageProps} statusCode={errorStatus} />
        </Layout>
      </NavProvider>
    </>
  ) : (
    <Provider store={store}>
      <Head />
      <HostProvider host={host}>
        <NotificationProvider>
          <NavProvider>
            <NProgress />
            <Notifications />
            <Component {...pageProps} />
          </NavProvider>
        </NotificationProvider>
      </HostProvider>
    </Provider>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { Component, ctx } = appContext
  let errorStatus = ctx.err ? ctx.err.statusCode : ctx.res ? ctx.res.statusCode : null
  let hasError = false
  let appProps

  try {
    appProps = await App.getInitialProps(appContext)
  } catch (error) {
    errorStatus = +error.statusCode || 500
    hasError = true
    if (ctx.res && errorStatus) ctx.res.statusCode = errorStatus
  }

  const host = getHost(appContext.ctx.req)

  return { ...appProps, host, hasError: Component === Error || hasError, errorStatus }
}
