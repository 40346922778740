import { Container, Title, Paragraph, Button, LazyPicture } from '@/components'
import styles from './Error.module.scss'
import Router from 'next/router'
import Link from 'next/link'

export default function Error({ statusCode }: { statusCode: number }) {
  const is404page = statusCode === 404

  return (
    <div className={styles.error}>
      <Container className={styles.container}>
        <div className={styles.img}>
          {is404page ? (
            <LazyPicture
              data={{
                desktop: {
                  x1: '/images/404/img-d.png',
                  x2: '/images/404/img-d.png',
                  webp_x1: '/images/404/img-d.webp',
                  webp_x2: '/images/404/img-d.webp',
                },
                tablet: {
                  x1: '/images/404/img-d.png',
                  x2: '/images/404/img-d.png',
                  webp_x1: '/images/404/img-d.webp',
                  webp_x2: '/images/404/img-d.webp',
                },
                mobile: {
                  x1: '/images/404/img-m.png',
                  x2: '/images/404/img-m.png',
                  webp_x1: '/images/404/img-m.webp',
                  webp_x2: '/images/404/img-m.webp',
                },
              }}
            />
          ) : (
            <LazyPicture
              data={{
                desktop: {
                  x1: '/images/500/img-d.png',
                  x2: '/images/500/img-d.png',
                  webp_x1: '/images/500/img-d.webp',
                  webp_x2: '/images/500/img-d.webp',
                },
                tablet: {
                  x1: '/images/500/img-d.png',
                  x2: '/images/500/img-d.png',
                  webp_x1: '/images/500/img-d.webp',
                  webp_x2: '/images/500/img-d.webp',
                },
                mobile: {
                  x1: '/images/500/img-m.png',
                  x2: '/images/500/img-m.png',
                  webp_x1: '/images/500/img-m.webp',
                  webp_x2: '/images/500/img-m.webp',
                },
              }}
            />
          )}
        </div>

        <div>
          {is404page ? <Title>Page not found</Title> : <Title>Internal server error</Title>}

          <Paragraph>
            {is404page
              ? 'It may have been deleted or its address was entered incorrectly.'
              : 'Try reloading the page later.'}
          </Paragraph>

          <div className={styles.btn}>
            {is404page ? (
              <Link href="/" passHref>
                <Button component="a" type="button" color="decor" variant="primary" size="large">
                  Back to Home page
                </Button>
              </Link>
            ) : (
              <Button
                type="button"
                color="decor"
                variant="primary"
                size="large"
                onClick={() => {
                  Router.reload()
                }}
              >
                Reload
              </Button>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}
